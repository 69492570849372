<template>
  <el-card class="box-card mt-3" id="special">
    <div slot="header" class="clearfix">
      <span><h4>Nổi bật</h4></span>
    </div>
    <div v-if="infomation" v-html="infomation.special">
    </div>
    <div v-else>Chưa có tin nổi bật</div>
  </el-card>
</template>

<script>
export default {
  name: "Special",
  props: {
    infomation: {},
  }
}
</script>

<style scoped>

</style>