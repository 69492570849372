<template>
  <div class="__header mb-3 sticky-header">
    <el-card class="box-card mt-3" id="edutalk-guard">
      <el-tabs class="common-active-menu" @tab-click="jumpToHTMl">
          <el-tab-pane label="Catalog" class="common-active" name="roadmap"> </el-tab-pane>
          <el-tab-pane label="Khai giảng" class="common-active" name="opening"> </el-tab-pane>
          <el-tab-pane label="Khuyến mãi" class="common-active" name="promotion"> </el-tab-pane>
          <el-tab-pane class="padding: 0 25px;" label="Edutalk bảo vệ" name="edutalk-guard"> </el-tab-pane>
      </el-tabs>
    </el-card>

  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    center: null
  },
  methods: {
    jumpToHTMl(event) {
      if (event.name === 'edutalk-guard') {
        window.location = 'https://edutalk.edu.vn/chinh-sach-bao-ve';
        return;
      }
      let id = event.name;
      var targetEle = document.getElementById(id);
      let pos = targetEle.style.position;
      let top = targetEle.style.top;
      targetEle.style.position = 'relative';
      targetEle.style.top = '-220px';
      targetEle.scrollIntoView({behavior: 'smooth', block: 'start'});
      targetEle.style.top = top;
      targetEle.style.position = pos;
    },
  },
}
</script>

<style>
div.el-tabs__item[aria-controls='pane-edutalk-guard'] {
  background: #886ab5;
  color: yellow;
  border-radius: 0.25rem;
}

.el-tabs__nav-wrap::after {
  z-index: -1 !important;
}
#tab-edutalk-guard {
    padding: 0 25px;
}
.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 121px;
  z-index: 3;
}

.el-tabs__header {
  margin: 12px 0 !important;
}

.center-name {
  font-size: 27px;
}

</style>
