<template>
  <el-card class="box-card mt-3" id="feedback">
    <div slot="header" class="clearfix">
      <span><h4>Đánh giá</h4></span>
    </div>
    <div v-if="infomation" v-html="infomation.feedback">
    </div>
    <div v-else>Không có thông tin đánh giá</div>
  </el-card>
</template>

<script>
export default {
  name: "Feedback",
  props: {
    infomation: {},
  }
}
</script>

<style scoped>

</style>