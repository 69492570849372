<template>
  <el-card class="box-card" id="general">
    <div slot="header" class="clearfix">
      <span><h4>Thông tin chung</h4></span>
    </div>
    <div v-if="infomation" v-html="infomation.intro">
    </div>
    <div v-else>Chưa có thông tin chung</div>
  </el-card>
</template>

<script>
export default {
  name: "General",
  props: {
    infomation: {},
  }
}
</script>

<style scoped>

</style>