<template>
  <div class="row">
    <div class="col-md-12">
      <p class="bold center-name">{{ center_detail.center ? center_detail.center.name : '' }}</p>
      <p v-if="center_detail.center"
         v-html="center_detail.center ? center_detail.center.short_description : ''"></p>
      <div class="content-detail-center overflow-auto">
          <Roadmap :infomation="center_detail.information"></Roadmap>

      </div>
        <div id="tim-kiem-khoa-hoc" v-if="this.$route.query.customer_id">
            <router-link class="sub-title"
                         :to="{ name: 'advise-course', query : {
                       center_id : this.$route.params.center_id,
                       customer_id : this.$route.query.customer_id,
                       payment : this.$route.query.payment
                     } }">
                <div class="sub-title">Tìm kiếm khóa học</div>
            </router-link>
        </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import '@/assets/css/tuvanvien.scss';
import General from "./component-detail-center/General";
import Special from "./component-detail-center/Special";
import PriceList from "./component-detail-center/PriceList";
import Roadmap from "./component-detail-center/Roadmap";
import Teacher from "./component-detail-center/Teacher";
import Opening from "./component-detail-center/Opening";
import Promotion from "./component-detail-center/Promotion";
import Feedback from "./component-detail-center/Feedback";
import Payment from "./component-detail-center/Payment";
import Header from "./component-detail-center/Header";
import {GET_DETAIL_CENTER} from "../../../../core/services/store/tuVan.module";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

const _ = deepdash(lodash);

export default {
  name: "DetailCenter",
  components: {
    Header,
    Payment,
    Feedback,
    Promotion,
    Opening,
    Teacher,
    Roadmap,
    PriceList,
    Special,
    General
  },
  data() {
    return {
      loading: false,
      center_detail: {}
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.getCenterById();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Chi tiết trung tâm", icon: ''}
    ]);
  },
  methods: {
    handleScroll() {
      let scrollTop = window.pageYOffset;
      let content = document.querySelector(".content-detail-center");
      _.each(content.childNodes, (item) => {
        let linkMenu = document.querySelector(".el-tabs__item");
        linkMenu.classList.remove('is_active');
        let current = document.getElementById(`tab-${item.id}`)
        let width = 0;
        let left = current.offsetLeft;
        if (item.offsetTop <= scrollTop && item.offsetTop + item.offsetHeight > scrollTop) {
          current.classList.add('is-active');
          let activeBar = document.querySelector(".el-tabs__active-bar");
          if (`${item.id}` === 'general' || `${item.id}` === 'payment') {
            width = current.offsetWidth - 20;
          } else {
            width = current.offsetWidth - 40;
            left += 20
          }
          activeBar.style.width = `${width}px`
          activeBar.style.transform = `translateX(${left}px)`;
        } else {
          current.classList.remove('is-active');
        }


      })
    },
    getCenterById() {
      this.$store.dispatch(GET_DETAIL_CENTER, {
        center_id: this.$route.params.center_id
      }).then((res) => {
        if (!res.error) {
          this.center_detail = res.data;
        }
      })
    }
  },
}
</script>
<style>
#tim-kiem-khoa-hoc {
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  z-index: 9999;
}

#tim-kiem-khoa-hoc .sub-title {
  text-align: center;
  width: 15%;
  height: 42px;
  line-height: 44px;
  margin: auto;
  background: #886ab5;
  color: yellow;
  border-radius: 28px;
  font-size: 16px;
}
</style>
