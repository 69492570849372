<template>
  <el-card class="box-card mt-3" id="promotion">
    <div slot="header" class="clearfix">
      <span><h4>Khuyến mãi</h4></span>
    </div>
    <div v-if="infomation" v-html="infomation.sale">
    </div>
    <div v-else>Không có thông tin khuyến mãi</div>
  </el-card>
</template>

<script>
export default {
  name: "Promotion",
  props: {
    infomation: {}
  }
}
</script>

<style scoped>

</style>