<template>
  <el-card class="box-card mt-3" id="payment">
    <div slot="header" class="clearfix">
      <span><h4>Thanh toán</h4></span>
    </div>
    <div class="section-detail">
      <h3 class="title">Thanh toán</h3>
      <div class="thanhtoan-content">
        <div class="mt-3">Cách 1: Thanh toán trực tiếp tại quầy lễ tân</div>
        <div class="mt-3">Cách 2: Chuyển khoản qua tài khoản ngân hàng</div>
        <div class="picture-fixed">
          <div id="picture-thanhtoan">
            <div style="width: 100%">
              <img :src="publicPath + '/media/images/logo/Logo_Edutalk.png'" alt="">
            </div>
            <div id="tttk">
              STK: 4666999666999 <br>
              Chủ Tk: Hồ Văn Hoàn
            </div>
            <div id="mb_picture" style="width: 100%">
              <img :src="publicPath + '/media/images/logo/mb_bank.png'" alt="">
            </div>
          </div>
        </div>
        <div class="mt-3" id="info">Ngân hàng thương mại cổ phần Quân đội (MBBank)<br>
          <div class="mt-3">Chủ tài khoản: Hồ Văn Hoàn<br></div>
          <div class="mt-3">Số tài khoản: 4666999666999 <br></div>
          <div class="mt-3" style="font-weight: normal">Nội dung: Tên học viên_Số điện thoại đăng
            ký hợp đồng_Trung tâm học
          </div>
        </div>
      </div>
    </div>

  </el-card>
</template>

<script>
export default {
  name: "Payment",
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
    }
  }
}
</script>

<style>

.thanhtoan-content {
  font-weight: 500;
  position: relative;
}

.thanhtoan-content .picture-fixed {
  position: absolute;
  margin: auto;
  width: 100%;
}

.thanhtoan-content #info {
  height: 250px;
}

#picture-thanhtoan {
  margin: auto;
  text-align: center;
  width: 200px;
  height: 250px;
  display: flex;
  align-content: space-around;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #4dfff8;
  background-image: linear-gradient(#4dfff8, #0b93d5);
  padding: 5px 0;
}

#picture-thanhtoan img {
  height: 50px;

}

#tttk {
  font-weight: 500;
}

</style>