<template>
  <div class="edu-card">
    <div class="edu-card-header">
      <div class="edu-image">
        <div class="teacher-card">
          <div class="teacher-image">
            <img :src="item.teacher.avatar" width="50" height="50" alt="">
          </div>
          <div class="teacher-detail">
            <h4>
              {{ item.teacher ? item.teacher.name : '' }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="edu-card-body">
      <h3 class="title-single"><a href="">{{ item.name }}
        {{ item.course ? `(${item.course.name})` : '' }}</a></h3>
      <div class="detail">
        <img :src="`${publicPath}/media/images/center-detail/position-marker.svg`" width="50" height="50" alt="">
        <router-link
            :to="{ path: '/detail-center/'+ center.id, query: { id:currentUser.id, payment : 0 } }"
            style="text-decoration: unset"
        >{{ center.name }}
        </router-link>
        <p class="ml-1">{{ center.address }}</p>
      </div>
      <p class="detail">
        <img :src="`${publicPath}/media/images/center-detail/opening-times.svg`" width="50" height="50" alt="">
        <b>Khai giảng: {{ item.start_date | formatDate }}</b>
      </p>
      <p class="price">{{ getPrice(item) }}</p>
    </div>
    <div class="edu-card-footer">
      <p class="students">
        <img :src="`${publicPath}/media/images/center-detail/user_amount.svg`" width="50" height="50" alt="">
        <b><span class="space">{{ item.current_student }}</span>/{{ item.number_student }} học viên
        </b>
      </p>
      <p>
        <router-link
            v-if="item.course"
            :to="{ path: '/advise/detail-classroom/'+ item.id, query: {
                customer_id:item.id,
               payment : this.$route.query.payment,
               branch_id : item.branch_id,
               classroom_id : item.id,
            } }"
            style="text-decoration: unset"
        >Xem chi tiết
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {formatVND} from "../../../../../core/filters";

export default {
  name: "OpeningItem",
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
    }
  },
  props: {
    item: {},
    center: {},
  },
  methods: {
    getPrice(item) {
      if (item.course) {
        let price = item.course.fee - (item.course.sale * item.course.fee / 100);
        return formatVND(price);
      } else {
        return '0đ';
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>

<style scoped>

</style>