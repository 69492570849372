<template>
    <el-card class="box-card mt-3" id="opening">
        <div slot="header" class="clearfix">
            <span><h4>Khai giảng</h4></span>
        </div>
        <div v-if="infomation" v-html="infomation.special">
        </div>
        <div v-else>Không có thông tin Khai giảng</div>
    </el-card>
</template>
<script>

import OpeningItem from "./OpeningItem";
import '@/assets/css/advise.css';

export default {
  name: "Opening",
  props: {
      infomation: {}
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
    }
  },
}
</script>

<style scoped>

</style>
