<template>
  <el-card class="box-card mt-3 width-mobi" id="roadmap">
    <div slot="header" class="clearfix">
      <span><h4>Công cụ bán hàng</h4></span>
    </div>
    <div v-if="infomation" v-html="infomation.schedule">
    </div>
    <div v-else>Chưa có lộ trình</div>
  </el-card>

</template>

<script>
export default {
  name: "Roadmap",
  props: {
    infomation: {},
  }
}
</script>

<style scoped>
@media only screen and (max-width: 430px) {
  .width-mobi {
    width: 1920px;
  }
}
</style>