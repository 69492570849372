<template>
  <el-card class="box-card mt-3" id="teacher">
    <div slot="header" class="clearfix">
      <span><h4>Giáo viên</h4></span>
    </div>
    <div v-if="infomation" v-html="infomation.teacher">
    </div>
    <div v-else>Chưa có thông tin giáo viên</div>
  </el-card>
</template>

<script>
export default {
  name: "Teacher",
  props: {
    infomation: {},
  }
}
</script>

<style scoped>

</style>